import { HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  left?: ReactNode;
  right?: ReactNode;
}

export function PageActions({ left, right }: Props) {
  return (
    <HStack marginY="5" alignItems="center" justifyContent="space-between">
      <HStack alignItems="center" justifyContent="flex-end">
        {left}
      </HStack>
      <HStack alignItems="center" justifyContent="flex-end">
        {right}
      </HStack>
    </HStack>
  );
}
