import { Box } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { BaseSyntheticEvent } from 'react';
import DatePicker from 'react-datepicker';

export const TimeSelect = ({
  value,
  property,
  onChange,
  disabled,
}: {
  value: string;
  property: 'start' | 'end';
  onChange: (property: 'start' | 'end', value: string) => void;
  disabled?: boolean;
}) => {
  const [hour, minute] = value.split(':').map((h) => parseInt(h));

  return (
    <Box
      sx={{
        ...(disabled && {
          '.react-datepicker__input-container': {
            border: '1px solid',
            borderColor: 'gray.300',
            background: 'gray.50',
            opacity: 0.5,
          },
        }),
        ...(property === 'end' && {
          // Put 00:00 last to indicate that it's the end of the day, not the start
          '.react-datepicker__time-list': {
            display: 'flex',
            flexDirection: 'column',
          },
          '.react-datepicker__time-list li:first-of-type': {
            order: 999,
          },
        }),
      }}
    >
      <DatePicker
        selected={DateTime.fromObject({ hour, minute }).toJSDate()}
        onChange={(date, e: BaseSyntheticEvent | undefined) => {
          if (e) {
            // Type
            onChange(property, e?.target?.value);
          } else if (date) {
            // Click
            onChange(property, DateTime.fromJSDate(date).toFormat('HH:mm'));
          }
        }}
        showTimeSelect
        showTimeSelectOnly
        timeCaption={''}
        timeIntervals={15}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        disabled={disabled}
        value={disabled ? '--:--' : undefined}
      />
    </Box>
  );
};
