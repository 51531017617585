import { FormControl, FormLabel, GridItem, Stack, Text } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import gql from 'graphql-tag';
import { isNil } from 'lodash';
import { useAuth } from '~auth/useAuth';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { PowerSettingsSection_DisplayFragment } from './__generated__/PowerSettingsSection.graphql';
import { DisplaySignalDetectionToggle } from './DisplaySignalDetection';

interface Props {
  display: PowerSettingsSection_DisplayFragment;
}

export function PowerSettingsSection({ display }: Props) {
  const { verifyUserPermissions } = useAuth();

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  return (
    <GridItem colSpan={{ base: 8, md: 7 }}>
      <Stack direction="column" spacing="10">
        {!isNil(display.signalDetection?.reported) && (
          <FormControl>
            <FormLabel>
              Signal Detection
              <FormLabelPendingIndicator
                isPending={isPropertyPending(display.recommendedSettings)}
              />
            </FormLabel>

            <Text color="gray.500" mb="2">
              {
                "When signal detection is enabled, the display will turn to standby once it detects that there's no content playing on the display."
              }
            </Text>

            <DisplaySignalDetectionToggle
              display={display}
              isDisabled={isDisplaySettingsUpdateDisabled}
            />
          </FormControl>
        )}
      </Stack>
    </GridItem>
  );
}

PowerSettingsSection.graphql = {
  fragments: {
    PowerSettingsSection_display: gql`
      fragment PowerSettingsSection_display on Display {
        id
        power {
          reportedAt
          desired
        }
        ...DisplaySignalDetectionToggle_display
        ...usePowerToggle_display
      }
    `,
  },
};
