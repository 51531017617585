import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { forwardRef, LegacyRef, MouseEventHandler } from 'react';
import { MoreIconVertical } from '~components/ui/icons';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';

interface Props {
  onEdit: () => void;
  onDelete: () => void;
}

export function CustomersGridItemActions({ onEdit, onDelete }: Props) {
  return (
    <StopClickPropagation position="relative">
      <Menu>
        <MenuButton as={CustomerActionsButton} />
        <MenuList>
          <MenuItem onClick={onEdit}>Edit</MenuItem>
          <MenuItem onClick={onDelete} color="red.500">
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    </StopClickPropagation>
  );
}

const CustomerActionsButton = forwardRef(function CustomerActionsButton(
  { onClick }: { onClick?: MouseEventHandler<HTMLDivElement> },
  ref: LegacyRef<HTMLDivElement>,
) {
  return (
    <Box
      ref={ref}
      role="button"
      color="gray.800"
      borderRadius="full"
      border="1px solid"
      borderColor="gray.50"
      padding="1"
      marginTop="-1"
      marginRight="-1"
      _hover={{
        border: '1px solid',
        borderColor: 'gray.100',
        background: 'gray.50',
        color: 'gray.900',
      }}
      onClick={onClick}
    >
      <MoreIconVertical width="6" height="6" />
    </Box>
  );
});
