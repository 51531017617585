import { Box, BoxProps, Button, chakra, Heading, HStack, Link, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '~components/ui/icons';
import { EmptySubscriptionsIllustration } from '~components/ui/illustrations/EmptySubscriptionsIllustration';

export default function SubscriptionsBanner(props: BoxProps) {
  return (
    <Box
      display="flex"
      gap="16"
      direction="row"
      position="relative"
      backgroundImage="linear-gradient(35deg, rgba(64, 28, 140, 0) 0%, rgba(129, 87, 216, 0.32) 45%, transparent 45%), linear-gradient(247.6deg, #4C21A4 43.31%, #230463 73.45%)"
      backgroundSize="50% 100%, cover"
      backgroundRepeat="no-repeat, no-repeat"
      paddingY="6"
      paddingX="10"
      alignItems="center"
      {...props}
    >
      <Box display={['none', 'none', 'none', 'block']}>
        <EmptySubscriptionsIllustration width="108" height="108" />
      </Box>
      <Box color="white" flex="1">
        <Heading fontFamily="heading" size="lg" mb="6">
          Introducing Wave Controller Lite and Essential
        </Heading>
        <Text fontSize="sm">
          As of April 8th Wave Controller has additional paid subscriptions to unlock advanced
          features.
          <br />
          Find out what subscription best fits your business needs on the{' '}
          <Link
            href={process.env.REACT_APP_WAVE_DOCS_SUBSCRIPTIONS_LINK}
            isExternal
            textDecoration="underline"
          >
            documentation portal
          </Link>
          .
        </Text>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display={['block', 'block', 'block', 'none']} mb="6">
          <EmptySubscriptionsIllustration width="108" height="108" />
        </Box>
        <Link href={process.env.REACT_APP_WAVE_DOCS_SUBSCRIPTIONS_LINK} target="_blank">
          <Button variant="solid" colorScheme="purple">
            <HStack>
              <chakra.span textDecoration="none">Documentation portal</chakra.span>
              <ExternalLinkIcon />
            </HStack>
          </Button>
        </Link>
      </Box>
    </Box>
  );
}
