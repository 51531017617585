import { gql } from '@apollo/client';
import { Grid, GridProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAnalyticsReporter } from '~utils/analytics';
import { MaybePromise } from '~utils/types';
import { CustomersGrid_CustomerFragment } from './__generated__/CustomersGrid.graphql';
import { CustomersGridItem_CustomerFragment } from './__generated__/CustomersGridItem.graphql';
import { CustomersGridItem } from './CustomersGridItem';
import { useDeleteCustomer } from './mutations/DeleteCustomer';

type Props = GridProps & {
  customers: CustomersGrid_CustomerFragment[];
  onCustomerDeleted: () => MaybePromise<void>;
};

export function CustomersGrid({ customers, onCustomerDeleted, ...gridProps }: Props) {
  const navigate = useNavigate();
  const analytics = useAnalyticsReporter();
  const deleteCustomer = useDeleteCustomer({
    onCompleted: () => {
      analytics.track('customerDeleteComplete');
      return onCustomerDeleted();
    },
  });

  async function handleDelete(customer: CustomersGridItem_CustomerFragment) {
    analytics.track('customerDeleteStart');
    await deleteCustomer.action.askConfirmation(customer);
  }

  function handleSelect(customer: CustomersGridItem_CustomerFragment) {
    analytics.track('customerProfileOpen');
    navigate(customer.handle);
  }

  function handleEdit(customer: CustomersGridItem_CustomerFragment) {
    navigate(`../settings/customers/${customer.id}`, { state: { canGoBack: true } });
  }

  return (
    <>
      <Grid
        gridAutoRows="auto"
        gridTemplateColumns={{
          xl: 'repeat(4, 1fr)',
          lg: 'repeat(3, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
        gap={'1rem'}
        {...gridProps}
      >
        {customers.map((customer) => (
          <CustomersGridItem
            key={customer.id}
            customer={customer}
            onSelect={handleSelect}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        ))}
      </Grid>
      {deleteCustomer.action.confirmationNode}
    </>
  );
}

CustomersGrid.graphql = {
  fragments: {
    CustomersGrid_customer: gql`
      fragment CustomersGrid_customer on Customer {
        id
        ...CustomersGridItem_customer
        ...DeleteCustomer_customer
      }
    `,
  },
};
