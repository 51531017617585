import { ApolloError, gql } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { GraphQLErrors } from '@apollo/client/errors';
import { Box, Button, Link, useDisclosure, VStack } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { isNil } from 'lodash';
import isNumber from 'lodash/isNumber';
import uniq from 'lodash/uniq';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Shield } from '~auth/Shield';
import { useAuth } from '~auth/useAuth';
import {
  BulkActionModal_CustomerFragment,
  BulkActionModal_DisplayFragment,
  BulkActionModal_OrganizationFragment,
} from '~components/displays/BulkAction/__generated__/BulkActionModal.graphql';
import { BulkActionButton } from '~components/displays/BulkAction/BulkActionButton';
import { BulkActionModal } from '~components/displays/BulkAction/BulkActionModal';
import { ClaimDisplayModal } from '~components/displays/ClaimDisplay/ClaimDisplayModal';
import { DisplayTable, useDisplayTable } from '~components/displays/DisplayTable';
import { Columns } from '~components/displays/DisplayTable/constants';
import {
  DisplayTableContext,
  DisplayTableProvider,
} from '~components/displays/DisplayTable/DisplayTableContext';
import { useFilterOptions } from '~components/displays/DisplayTable/Filtering/useFilterOptions';
import { EmptyView, EmptyViewButton } from '~components/EmptyView';
import UpgradeToWaveEssentialModal from '~components/organization/UpgradeToWaveEssentialModal/UpgradeToWaveEssentialModal';
import { StatisticsItem } from '~components/statistics/StatisticsBar';
import UsageStatistics from '~components/statistics/UsageStatistics';
import { BackButton } from '~components/ui/BackButton';
import { HelpIcon } from '~components/ui/icons';
import { EmptyDisplayIllustration } from '~components/ui/illustrations/EmptyDisplayIllustration';
import { Page } from '~components/ui/Page';
import { PageActions } from '~components/ui/PageActions';
import { PageContent } from '~components/ui/PageContent';
import { PageHeading } from '~components/ui/PageHeading';
import { PageLoader } from '~components/ui/PageLoader';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { Exact } from '~graphql/__generated__/types';
import { HandleApiError } from '~graphql/HandleApiError';
import { useAnalyticsReporter } from '~utils/analytics';
import { useFeatureFlag } from '~utils/features';
import { isWaveSubscription } from '~utils/subscriptions';
import { ensure, isDefined } from '~utils/types';
import {
  CustomerDisplaysIndexPageQuery,
  useCustomerDisplaysIndexPageQuery,
} from './__generated__/Index.graphql';

export type DataWithCustomer = Omit<CustomerDisplaysIndexPageQuery, 'customer'> & {
  customer: CustomerDisplaysIndexPageQuery['customer'];
};
type DisplayOverviewHeadingProps = {
  data: DataWithCustomer;
};

type CustomerDisplaysOverviewProps = {
  data: DataWithCustomer;
  error: ApolloError | undefined;
  refetch: (
    variables?: Partial<Exact<{ customerHandle: string }>>,
  ) => Promise<ApolloQueryResult<CustomerDisplaysIndexPageQuery>>;
};

function DisplayOverviewHeading({ data }: DisplayOverviewHeadingProps) {
  const navigate = useNavigate();

  const tableContext = useContext(DisplayTableContext);

  if (isNil(tableContext)) {
    throw new Error('TableContext can only be used within TableContext Provider');
  }

  const { table } = tableContext;

  const handleGoBack = () => {
    navigate('/');
  };

  const hasWaveSubscription = isWaveSubscription(data.customer?.waveSubscription);

  const numberOfDisplays = data.customer?.displays.length ?? 0;

  const shouldShowStatistics = hasWaveSubscription && numberOfDisplays > 0;

  const filters = useFilterOptions(table?.state.filters ?? [], table?.data);

  const handleItemClicked = (item: StatisticsItem) => {
    const itemCanApplyFilter = ['Connected', 'Disconnected', 'Standby'].includes(item.name);

    if (itemCanApplyFilter) {
      table?.setAllFilters([
        {
          id: Columns.Site,
          value: [],
        },
        {
          id: Columns.Groups,
          value: [],
        },
        {
          id: Columns.Firmware,
          value: [],
        },
        {
          id: Columns.Playlist,
          value: [],
        },
        {
          id: Columns.PowerSchedule,
          value: [],
        },
        {
          id: Columns.Warnings,
          value: [],
        },
        {
          id: Columns.Status,
          value: [],
        },
      ]);
      table?.setFilter(
        Columns.Status,
        filters.status.all.filter((f) => f.value === item.name),
      );
    }
  };

  return (
    <>
      <PageHeading floatingButton={<BackButton onClick={handleGoBack} />}>
        Display overview
      </PageHeading>
      {shouldShowStatistics && (
        <UsageStatistics customer={data.customer} onItemClicked={handleItemClicked} />
      )}
    </>
  );
}

function DisplayOverview({ data, error, refetch }: CustomerDisplaysOverviewProps) {
  const { customer } = useParams();
  const navigate = useNavigate();
  const claimDisplayModal = useDisclosure();
  const bulkActionModal = useDisclosure();
  const upgradeToWaveEssentialModal = useDisclosure();
  const analytics = useAnalyticsReporter();
  const { verifyUserPermissions } = useAuth();

  // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const displays = data?.customer?.displays ?? [];

  const table = useDisplayTable(displays);

  const tableContext = useContext(DisplayTableContext);

  if (isNil(tableContext)) {
    throw new Error('TableContext can only be used within TableContext Provider');
  }

  const { setTable } = tableContext;

  useEffect(() => {
    setTable?.(table);
  }, [table, setTable]);

  const selectedCount = table.selectedFlatRows.length;

  async function handleDisplayClicked(id: string) {
    analytics.track('displayDetail');
    navigate(id);
  }

  async function handleClaimDisplay() {
    analytics.track('displayClaimStart');
    claimDisplayModal.onOpen();
  }

  async function handleClaimDisplaySucceeded(display: { id: string }) {
    analytics.track('displayClaimComplete');
    claimDisplayModal.onClose();
    await refetch({ customerHandle: customer });
    navigate(display.id);
  }

  async function handleBulkActionSucceeded() {
    bulkActionModal.onClose();
    await refetch({ customerHandle: customer });
  }

  const extractInvalidDisplayIds = (graphQLErrors: GraphQLErrors | undefined): string[] => {
    if (!graphQLErrors) {
      return [];
    }

    const invalidDisplayIds = graphQLErrors?.map(({ path }) => {
      if (!path) {
        return;
      }

      const [c, d, index] = path;
      if (c === 'customer' && d === 'displays' && isNumber(index)) {
        return data?.customer?.displays[index as number]?.id;
      }
    });

    return uniq(invalidDisplayIds?.filter((id) => isDefined(id))) as string[];
  };

  const openBulkActions = () => {
    // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
    if (isSubscriptionsEnabled && hasLiteSubscription) {
      upgradeToWaveEssentialModal.onOpen();
      return;
    }

    analytics.track('displayOpenBulkActions');
    bulkActionModal.onOpen();
  };

  const hasLiteSubscription = !isWaveSubscription(data.customer?.waveSubscription);

  const hasDisplayClaimPermission = verifyUserPermissions([Permission.DisplayClaim]);

  return (
    <>
      {displays.length > 0 && (
        <PageActions
          left={
            <>
              {selectedCount > 0 ? (
                <BulkActionButton selectedCount={selectedCount} onClick={openBulkActions} />
              ) : null}
              <DisplayTable.Filter table={table} />
            </>
          }
          right={
            <Shield requiredPermissions={[Permission.DisplayClaim]}>
              <Button variant="solid" colorScheme="blue" onClick={handleClaimDisplay}>
                Claim display
              </Button>
            </Shield>
          }
        />
      )}

      <Box
        boxShadow="elevated"
        border="1px solid"
        borderRadius="md"
        borderColor="gray.100"
        overflowX="auto"
        bgColor="white"
      >
        {displays.length === 0 ? (
          <VStack paddingTop={20} paddingBottom={20} spacing={8}>
            <EmptyView
              icon={<EmptyDisplayIllustration />}
              title="Claim your first display"
              description={`Open up the “Wave” app on your display and fill in the claiming code to add the display to this customer.${
                hasDisplayClaimPermission
                  ? ''
                  : ' Ask your organization to grant you necessary permissions to do so.'
              }`}
              padding="0"
            >
              <EmptyViewButton
                label="Claim display"
                onClick={handleClaimDisplay}
                isDisabled={!hasDisplayClaimPermission}
              />
            </EmptyView>
            <Link
              isExternal
              href="https://docs.wave.ppds.com/"
              color="blue.500"
              target="_blank"
              rel="noreferrer"
              display="flex"
              alignItems="center"
            >
              <HelpIcon color="blue.500" width={5} height={5} marginRight={2} />
              Need help?
            </Link>
          </VStack>
        ) : (
          <DisplayTable
            table={table}
            onGoToDetail={(id) => handleDisplayClicked(id)}
            invalidDisplayIds={extractInvalidDisplayIds(error?.graphQLErrors)}
          />
        )}
      </Box>
      <Box display="flex" marginTop="4">
        <Box flex="1">
          <DisplayTable.PageSizeSelector table={table} />
        </Box>
        <DisplayTable.Pagination table={table} />
      </Box>
      {
        // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
      }
      {isSubscriptionsEnabled && hasLiteSubscription ? (
        <UpgradeToWaveEssentialModal
          isOpen={upgradeToWaveEssentialModal.isOpen}
          onClose={upgradeToWaveEssentialModal.onClose}
        />
      ) : (
        <BulkActionModal
          customer={data.customer as BulkActionModal_CustomerFragment}
          displays={table.selectedFlatRows.map(
            (row) => row.original as unknown as BulkActionModal_DisplayFragment,
          )}
          organization={data.organization as BulkActionModal_OrganizationFragment}
          isOpen={bulkActionModal.isOpen}
          onCancel={bulkActionModal.onClose}
          onSuccess={handleBulkActionSucceeded}
        />
      )}
      <ClaimDisplayModal
        customer={data.customer as BulkActionModal_CustomerFragment}
        isOpen={claimDisplayModal.isOpen}
        onCancel={claimDisplayModal.onClose}
        onSuccess={handleClaimDisplaySucceeded}
      />
    </>
  );
}

const POLL_INTERVAL = 10000;

export function CustomerDisplaysIndexPage() {
  const { customer } = useParams();

  const { data, loading, error, refetch, startPolling, stopPolling } =
    useCustomerDisplaysIndexPageQuery({
      variables: {
        customerHandle: ensure(customer),
      },
      pollInterval: POLL_INTERVAL,
      errorPolicy: 'all',
    });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  return (
    <Page title="Displays" pageName="displays_overview">
      <DisplayTableProvider>
        {!loading && !error && (
          <PageContent backgroundColor="gray.50">
            <DisplayOverviewHeading data={data as DataWithCustomer} />
          </PageContent>
        )}
        <PageContent>
          {loading ? (
            <PageLoader />
          ) : error ? (
            <HandleApiError error={error} />
          ) : (
            isDefined(data?.customer) && (
              <DisplayOverview data={data as DataWithCustomer} error={error} refetch={refetch} />
            )
          )}
        </PageContent>
      </DisplayTableProvider>
    </Page>
  );
}

CustomerDisplaysIndexPage.graphql = {
  queries: {
    CustomerDisplaysIndexPage: gql`
      query CustomerDisplaysIndexPage($customerHandle: String!) {
        customer: customerByHandle(handle: $customerHandle) {
          id
          name
          ...ClaimDisplayModal_customer
          ...BulkActionModal_customer
          displays {
            id
            ...DisplayTable_display
            ...BulkActionModal_display
          }
          waveSubscription {
            usage {
              current
              max
            }
          }
          displayUsage {
            presence {
              connected
              disconnected
            }
            powerState {
              plannedOn
              plannedStandBy
              unplannedOn
              unplannedStandBy
              disconnected
            }
            contentSource {
              standby
              disconnected
              sources {
                source
                count
              }
            }
          }
        }
        organization {
          ...BulkActionModal_organization
        }
      }
    `,
  },
};
